import React from 'react';

function Card({name, count, url}) {
    return (
        <div className='card bg-light'>
            <h1 className='header-lg center-text'>
                <a href={url}>{name}</a>
            </h1>
            <h2 className='header-lg center-text'>
                Ж-{count}
            </h2>
        </div>
    )
}

function compareCards(a, b) {
    // Compare ratings first.
    if (a.count !== b.count) {
        return b.count - a.count;
    }
    // Then put the most recent to the top.
    if (a.recent.year !== b.recent.year) {
        return b.recent.year - a.recent.year;
    }
    if (a.recent.month !== b.recent.month) {
        return b.recent.month - a.recent.month;
    }
    return 0;
}

export default function Theatre({theatreDatabase}) {
    return (
        <ul className='grid space-around'>
            {theatreDatabase.sort(compareCards).map((d, ix) => {
                return (
                    <li key={ix}>
                        <Card name={d.name} count={d.count} url={d.url}/>
                    </li>
                );
            })}
        </ul>
    )
}