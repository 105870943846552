import React from 'react';

function Card({name, count, url}) {
    return (
        <div className='card bg-light'>
            <h1 className='header-lg center-text'>
                {url ? <a href={url}>{name}</a> : name}
            </h1>
            <h2 className='header-lg center-text'>
                Ж-{count}
            </h2>
        </div>
    )
}

export default function Restaurants({restaurantsDatabase}) {
    return (
        <ul className='grid space-around'>
            {restaurantsDatabase.map((d, ix) => {
                return (
                    <li key={ix}>
                        <Card name={d.name} count={d.count} url={d.url}/>
                    </li>
                );
            })}
        </ul>
    )
}