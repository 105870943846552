import React from 'react'
import Movies from './Movies';
import Restaurants from './Restaurants';
import Theatre from './Theatre';
import database from '../Database';

function CategoryNav({selected, onUpdateCategory}) {
    const categories = ['Movies', 'Theatre']

    return (
        <ul className='flex-center'>
            {categories.map((category) => (
                <li key={category}>
                    <button className='cat-btn cat-link'
                            style={category === selected ? {color: 'rgb(155, 155, 155)'} : {color: 'rgb(15, 15, 250)'}}
                            onClick={() => onUpdateCategory(category)} >
                        {category}
                    </button>
                </li>
            ))}
        </ul>
    )
}

export default class Main extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            selectedCategory: 'Movies',
        }

        this.updateCategory = this.updateCategory.bind(this)
    }
    componentDidMount() {
        this.updateCategory(this.state.selectedCategory)
    }

    updateCategory(selectedCategory) {
        this.setState({
            selectedCategory,
        })
    }

    render() {
        const {selectedCategory} = this.state

        return <React.Fragment>
            <CategoryNav
                selected={selectedCategory}
                onUpdateCategory={this.updateCategory}
            />
            {selectedCategory === 'Movies' && <Movies moviesDatabase={database['movies']} /> }
            {selectedCategory === 'Restaurants' && <Restaurants restaurantsDatabase={database['restaurants']} /> }
            {selectedCategory === 'Theatre' && <Theatre theatreDatabase={database['theatre']} /> }
        </React.Fragment>
    }

}