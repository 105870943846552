import React from 'react';

function Card({title, count, imdb}) {
    return (
        <div className='card bg-light'>
            <h1 className='header-lg center-text'>
                {title}
            </h1>
            <h2 className='header-lg center-text'>
                Ж-{count}
            </h2>
            {imdb && <a href={imdb}>IMDB</a>}
        </div>
    )
}

function compareCards(a, b) {
    // Compare ratings first.
    if (a.count !== b.count) {
        return b.count - a.count;
    }
    // Then put the most recent to the top.
    if (a.recent.year !== b.recent.year) {
        return b.recent.year - a.recent.year;
    }
    if (a.recent.month !== b.recent.month) {
        return b.recent.month - a.recent.month;
    }
    return 0;
}

export default function Movies({moviesDatabase}) {
    return (
        <ul className='grid space-around'>
            {moviesDatabase.sort(compareCards).map((d, ix) => {
                return (
                    <li key={ix}>
                        <Card title={d.title} count={d.count} imdb={d.imdb}/>
                    </li>
                );
            })}
        </ul>
    )
}