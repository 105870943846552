function createDatabase() {
    return {
        movies: [
            {title: 'Rams', count: 2, imdb: 'https://www.imdb.com/title/tt8737608/', recent: {year: 2020, month: 10}},
            {title: 'Corpus Christi', count: 1, imdb: 'https://www.imdb.com/title/tt8649186/', recent: {year: 2020, month: 11}},
            {title: 'Psycho', count: 1, imdb: 'https://www.imdb.com/title/tt0054215/', recent: {year: 2020, month: 11}},
            {title: 'On the Rocks', count: 1, imdb: 'https://www.imdb.com/title/tt9606374/', recent: {year: 2020, month: 11}},
            {title: 'Blithe Spirit', count: 1, imdb: 'https://www.imdb.com/title/tt10334148/', recent: {year: 2020, month: 11}},
            {title: 'Words on Bathroom Walls', count: 1, imdb: 'https://www.imdb.com/title/tt8045906/', recent: {year: 2020, month: 12}},
            {title: 'Misbehaviour', count: 1, imdb: 'https://www.imdb.com/title/tt9148706/', recent: {year: 2020, month: 12}},
            {title: 'Nomadland', count: 1, imdb: 'https://www.imdb.com/title/tt9770150/', recent: {year: 2020, month: 12}},
            {title: 'High Ground', count: 1, imdb: 'https://www.imdb.com/title/tt9286908/', recent: {year: 2021, month: 1}},
            {title: 'Another Round', count: 1, imdb: 'https://www.imdb.com/title/tt10288566/', recent: {year: 2021, month: 2}},
            {title: 'Promising Young Woman', count: 1, imdb: 'https://www.imdb.com/title/tt9620292/', recent: {year: 2021, month: 2}},
            {title: 'Minari', count: 1, imdb: 'https://www.imdb.com/title/tt10633456/', recent: {year: 2021, month: 2}},
            {title: 'Uncle Vanya', count: 1, imdb: 'https://www.imdb.com/title/tt13299678/', recent: {year: 2021, month: 4}},
            {title: 'The Father', count: 3, imdb: 'https://www.imdb.com/title/tt10272386/', recent: {year: 2021, month: 5}},
            {title: 'The Courier', count: 1, imdb: 'https://www.imdb.com/title/tt8368512/', recent: {year: 2021, month: 5}},
            {title: 'A Quiet Place Part II', count: 1, imdb: 'https://www.imdb.com/title/tt8332922/', recent: {year: 2021, month: 5}},
            {title: 'Bye Bye Morons', count: 1, imdb: 'https://www.imdb.com/title/tt10196398/', recent: {year: 2021, month: 6}},
            {title: 'Cruella', count: 1, imdb: 'https://www.imdb.com/title/tt3228774/', recent: {year: 2021, month: 6}},
            {title: 'Free Guy', count: 1, imdb: 'https://www.imdb.com/title/tt6264654/', recent: {year: 2021, month: 10}},
            {title: 'Perfumes', count: 1, imdb: 'https://www.imdb.com/title/tt11748354/', recent: {year: 2021, month: 10}},
            {title: 'Shang-Chi and the Legend of the Ten Rings', count: 1, imdb: 'https://www.imdb.com/title/tt9376612/', recent: {year: 2021, month: 10}},
            {title: 'Dune: Part One', count: 1, imdb: 'https://www.imdb.com/title/tt1160419/', recent: {year: 2021, month: 12}},
            {title: 'Delicious', count: 1, imdb: 'https://www.imdb.com/title/tt10738536/', recent: {year: 2021, month: 12}},
            {title: 'Spider-Man: No Way Home', count: 1, imdb: 'https://www.imdb.com/title/tt10872600/', recent: {year: 2021, month: 12}},
            {title: 'The Matrix Resurrections', count: 1, imdb: 'https://www.imdb.com/title/tt10838180/', recent: {year: 2021, month: 12}},
            {title: 'Ghostbusters: Afterlife', count: 1, imdb: 'https://www.imdb.com/title/tt4513678/', recent: {year: 2022, month: 1}},
            {title: 'The French Dispatch', count: 1, imdb: 'https://www.imdb.com/title/tt8847712/', recent: {year: 2022, month: 1}},
            {title: 'Drive My Car', count: 1, imdb: 'https://www.imdb.com/title/tt14039582/', recent: {year: 2022, month: 2}},
            {title: 'Belfast', count: 1, imdb: 'https://www.imdb.com/title/tt12789558/', recent: {year: 2022, month: 3}},
            {title: 'Death on the Nile', count: 1, imdb: 'https://www.imdb.com/title/tt7657566/', recent: {year: 2022, month: 3}},
            {title: 'Everything Everywhere All at Once', count: 1, imdb: 'https://www.imdb.com/title/tt6710474/', recent: {year: 2022, month: 4}},
            {title: 'The Northman', count: 1, imdb: 'https://www.imdb.com/title/tt11138512/', recent: {year: 2022, month: 5}},
            {title: 'Operation Mincemeat', count: 1, imdb: 'https://www.imdb.com/title/tt1879016/', recent: {year: 2022, month: 5}},
            {title: 'Official Competition', count: 1, imdb: 'https://www.imdb.com/title/tt11700260/', recent: {year: 2022, month: 7}},
        ],
        restaurants: [
            {name: 'Moretti Ristorante Pizzeria', count: 3, url: 'https://moretti.com.au/', recent: {year: 2021, month: 2}},
            {name: 'Koi Bento', count: 3, recent: {year: 2020, month: 12}},
        ],
        theatre: [
            {name: 'Rules for Living', count: 1, url: 'https://www.sydneytheatre.com.au/whats-on/productions/2020/Rules-for-Living', recent: {year: 2020, month: 12}},
            {name: 'The Picture of Dorian Gray', count: 1, url: 'https://www.sydneytheatre.com.au/whats-on/productions/2020/The-Picture-of-Dorian-Gray', recent: {year: 2021, month: 1}},
            {name: 'Frozen', count: 1, url: 'https://frozenthemusical.com.au/', recent: {year: 2021, month: 2}},
            {name: 'Playing Beatie Bow', count: 1, url: 'https://www.sydneytheatre.com.au/whats-on/productions/2021/playing-beatie-bow', recent: {year: 2021, month: 3}},
            {name: 'Home I\'m Darling', count: 1, url: 'https://www.sydneytheatre.com.au/whats-on/productions/2021/home-im-darling', recent: {year: 2021, month: 4}},
            {name: 'Hamilton', count: 2, url: 'https://hamiltonmusical.com.au', recent: {year: 2022, month: 2}},
            {name: 'Julius Caesar', count: 1, url: 'https://www.sydneytheatre.com.au/whats-on/productions/2021/julius-caesar', recent: {year: 2021, month: 12}},
            {name: 'Come From Away', count: 1, url: 'https://comefromaway.com.au', recent: {year: 2021, month: 12}},
            {name: 'Death Of A Salesman', count: 1, url: 'https://www.sydneytheatre.com.au/whats-on/productions/2021/death-of-a-salesman', recent: {year: 2021, month: 12}},
            {name: 'Triple X', count: 1, url: 'https://www.sydneytheatre.com.au/whats-on/productions/2022/triple-x', recent: {year: 2022, month: 2}},
            {name: 'Grand Horizons', count: 1, url: 'https://www.sydneytheatre.com.au/whats-on/productions/2022/grand-horizons', recent: {year: 2022, month: 2}},
            {name: 'North by Northwest', count: 1, url: 'https://www.sydneylyric.com.au/north-by-northwest/', recent: {year: 2022, month: 3}},
            {name: 'Blithe Spirit', count: 1, url: 'https://www.sydneytheatre.com.au/whats-on/productions/2022/blithe-spirit', recent: {year: 2022, month: 4}},
            {name: 'Strange Case of Dr Jekyll and Mr Hyde', count: 1, url: 'https://www.sydneytheatre.com.au/whats-on/productions/2022/strange-case-of-dr-jekyll-and-mr-hyde', recent: {year: 2022, month: 9}},
            {name: 'Mary Poppins', count: 1, url: 'https://www.sydneylyric.com.au/mary-poppins/', recent: {year: 2022, month: 10}},
        ],
    }
}

const Database = createDatabase()

export default Database
